import React from 'react'
import {Link, withRouter} from 'react-router-dom'

import AuthManager from '../../../utils/AuthManager'

import { MENU_ITEMS } from './LeftMenu'
import Backend from "../../../utils/Backend";
import Event from "../../../utils/Event";
import AsyncSelect from "../common/AsyncSelect";
import General from "../../../utils/General";

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedList: null,
      username: AuthManager.currentUser?.user.first_name
    }
  }

  componentDidMount() {
    if (AuthManager.currentUser.latest_list){
      Backend.getList(AuthManager.currentUser.latest_list).then(list => {
        this.setState({selectedList: list}, () => {
          Event.emit('LIST_CHANGE', list)
        })
      }).catch(e => {
        General.showNotification(e.message, false)
      })
    }else{
      Backend.getLists().then(lists => {
        this.setState({selectedList: lists[0]}, () => {
          Event.emit('LIST_CHANGE', lists[0])
        })
      }).catch(e => {
        General.showNotification(e.message, false)
      })
    }
    this._setUpEvents()
  }

  _setUpEvents(){
    Event.on('LIST_CHANGE', (list) => {
      this.setState({selectedList: list})
    })
  }

  _onLogoutPressed() {
    AuthManager.logout()
    this.props.history.push('/login')
  }

  render() {
    let {
      selectedList,
      username,
      title
    } = this.state

    let initial = username.charAt(0)
    let linkiesPage = window.location.pathname === "/"
    let listsPage = window.location.pathname === "/manage-collections"
    let value = {value: selectedList?.id, label: selectedList?.name, data: selectedList}

    return (
      <>
        <div id="kt_header" className="header align-items-stretch">
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div className="d-flex align-items-stretch" id="kt_header_nav">

                {
                  listsPage &&
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary w-100 mb-3 mt-3 custom-btn-font-size"
                    onClick={() => Event.emit('SHOW_LIST_MODAL', null)}
                  >
                    Add New Collection
                  </button>
                }

                {
                  linkiesPage &&
                  <>
                    <div className='row align-items-center collection'>
                      <div className='col-auto pe-0'>Collection</div>
                      <div className='col'>
                        <AsyncSelect
                          key={this.state.asyncKey}
                          endpoint={window.Api.Lists}
                          value={value}
                          onSelected={list => {
                            if(!list){
                              this.setState({selectedList: null, asyncKey: this.state.asyncKey+1})
                            }else{
                              this.setState({selectedList: list, asyncKey: this.state.asyncKey+1})
                            }
                            Backend.saveLastList({latest_list: list.id})
                            Event.emit('LIST_CHANGE', list)
                          }}
                          getOptions={lists => {
                            if(!this.state.selectedList){
                              this.setState({ selectedList: lists[0] })
                            }
                            return lists.map(list => ({
                                value: list.id,
                                label: list.name,
                                data: list
                              })
                            )}
                          }
                        />
                      </div>
                      {
                        !selectedList?.parent &&
                        <div className='col ps-0'>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary w-100 mb-3 mt-3 custom-btn-font-size"
                            onClick={() => Event.emit('SHOW_LINKIE_MODAL', {list:selectedList, linkie: null})}
                          >
                            Add New Website
                          </button>
                        </div>
                      }
                    </div>

                  </>
                }
              </div>
              <div className="d-flex align-items-stretch flex-shrink-0">
                <div className="d-flex align-items-stretch flex-shrink-0">
                  <div className="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                    <div className="cursor-pointer user-name" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
                         data-kt-menu-placement="bottom-end">
                      Hi, {username} <span className="ms-2">{initial}</span>
                    </div>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                      data-kt-menu="true">
                      <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                          <div className="d-flex flex-column">
                            <div className="fw-bolder d-flex align-items-center fs-5">
                              {username}
                            </div>
                            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">{AuthManager.currentUser?.user.email}</a>
                          </div>
                        </div>
                      </div>
                      <div className="separator my-2"></div>
                      <div className="menu-item px-5">
                        <a
                          className="menu-link px-1"
                          onClick={() => this._onLogoutPressed()}
                        >
                          Sign Out
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
                    <div className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                         id="kt_header_menu_mobile_toggle">
                      <span className="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                            fill="black"></path>
                          <path opacity="0.3"
                                d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                                fill="black"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}

export default withRouter(Header)
