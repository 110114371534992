import React from "react"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import BookmarkLists from "./components/linkie/Lists";
import AuthManager from "../utils/AuthManager";
import Event from "../utils/Event";

export default class Lists extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentUser: AuthManager.currentUser,
    }
    this._setUpEvents()
  }

  _setUpEvents(){

  }

  render() {
    let {
      selectedList
    } = this.state

    return (
      <div className="pages manage-lists">
        <div className="g-5 gx-xxl-8">
          <div className="text-center">
            <BookmarkLists/>
          </div>
        </div>
      </div>
    )
  }

}