import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class Confirm extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 my-7'
        >

          <div className="d-flex flex-column mb-7 fv-row">
            <p>This action is not reversible, are you sure you want to continue?</p>
          </div>

          <div className='text-center pt-15'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.props.onHide()}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.props.onConfirm()}
              >
                Confirm
              </button>
            </div>
          </div>

        </Modal.Body>
        
      </Modal>
    )
  }
}
