import React from 'react'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Alert from 'react-bootstrap/Alert'

import Backend from "../../../utils/Backend"
import AuthManager from "../../../utils/AuthManager"
import Event from "../../../utils/Event";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class List extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      error: null,
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _getState(props){
    return {
      ...props,
      show: props.show,
      list: props.list ? props.list : {type: "customer_custom"},
    }
  }

  _handleChange(e) {
    let list = this.state.list
    list[e.target.name] = e.target.value
    this.setState({ list, error: null })
  }

  _isFormValid(){
    let {
      name,
    } = this.state.list

    let error = null
    if(name == null || name.length === 0){
      error = "Please enter a valid title"
    }

    if(error){
      General.showNotification(error, false)
      return false
    }
    return true
  }

  _handleSave(){
    let {
      list
    } = this.state

    if(!this._isFormValid()){
      return
    }

    this.setState({ loading: true })

    if(list.id){
      this._updateList(list)
    }else{
      this._addList(list)
    }
  }

  _addList(list){
    Backend.addList(list).then(list => {
      this.setState({ loading: false })
      this.props.onSaved(list)
      Event.emit('LIST_CHANGE', list)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  _updateList(list){
    Backend.updateList(list).then(list => {
      this.setState({ loading: false })
      this.props.onSaved(list)
    }).catch(error => {
      this.setState({ loading: false })
      General.showNotification(error.message, false)
    })
  }

  render() {
    let {
      show,
      loading,
      list,
      error
    } = this.state

    let title = list.id ? "Update Website Collection" : "Add New Website Collection"
    let buttonTitle = list.id ? "Update" : "Save"

    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body
          className='scroll-y mx-5 mx-xl-15 my-7'
        >

          { error &&
            <Alert variant={"danger"}>
              { error }
            </Alert>
          }

          <div className="d-flex flex-column mb-7 fv-row">
            <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
              <span className="required">New Collection Name</span>
              <OverlayTrigger
                    overlay={
                      <Tooltip >
                        Enter the name of New Collection
                      </Tooltip>
                    }
                  >
                    <i className="fas fa-exclamation-circle ms-2 fs-7" aria-label="Enter the name of New Collection"></i>
                </OverlayTrigger>
            </label>
            <input
              type="text"
              name="name"
              className="form-control form-control-solid"
              value={ list.name }
              onChange={e => this._handleChange(e)}
            />
          </div>

          <div className='text-center pt-15'>
            <div className='buttons-grid gg-7'>
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
              >
                Discard
              </button>

              <button
                type="button"
                className="btn btn-primary"
                disabled={loading}
                onClick={() => this._handleSave()}
              >
                {buttonTitle}
              </button>
            </div>
          </div>


        </Modal.Body>
        
      </Modal>
    )
  }
}
