import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from './components/structure/LeftMenu'
import Footer from './components/structure/Footer'
import Header from './components/structure/Header'

import AsyncStorage from '../utils/AsyncStorage'

import Dashboard from "./Dashboard";
import ReadLater from "./ReadLater";
import Trending from "./Trending";
import Lists from "./Lists";
import List from "./components/modals/List";
import Linkie from "./components/modals/Linkie";

import Event from "../utils/Event";

const LOGO = require('../assets/media/logo.png')

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      refresh: false
    }
    this._setUpEvents()
  }

  _setUpEvents(){
    Event.on('SHOW_LINKIE_MODAL', (object) => {
      this.setState({selectedLinkieList: object.list, linkie: object.linkie, showLinkieModal: true})
    })

    Event.on('SHOW_LIST_MODAL', (list) => {
      this.setState({selectedList: list, showListModal: true})
    })
  }

  _renderRoutes(){
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/manage-collections" component={Lists} />
        <Route exact path="/read-later" component={ReadLater} />
        <Route exact path="/trending" component={Trending} />
      </Switch>
    )
  }

  render() {
    let {
      linkie,
      selectedList,
      showListModal,
      selectedLinkieList,
      showLinkieModal,
    } = this.state

    return (
      <>
        <div className="page-loader flex-column">
          <img alt="Logo" className="mh-75px" src={LOGO}/>
          <div className="d-flex align-items-center mt-5">
            <span className="spinner-border text-primary" role="status"></span>
            <span className="text-muted fs-6 fw-bold ms-5">Loading...</span>
          </div>
        </div>

        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <div id="notification-container" className="text-center">
              <div
                id="notification-content"
              ></div>
            </div>
            <LeftMenu/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>
              <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-fluid">
                    { this._renderRoutes() }
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
        {
          showListModal &&
          <List
            show={showListModal}
            list={selectedList}
            onHide={() => this.setState({showListModal: false, selectedList: null})}
            onSaved={() => this.setState({showListModal: false, selectedList: null}, () => Event.emit('REFRESH_LIST'))}
          />
        }
        {
          showLinkieModal &&
          <Linkie
            show={showLinkieModal}
            linkie={linkie}
            list={selectedLinkieList}
            onHide={() => this.setState({showLinkieModal: false, selectedLinkie: null})}
            onSaved={() => {
              this.setState({
                showLinkieModal: false,
                selectedLinkie: null
              }, () => Event.emit('REFRESH_LINKIES'))
            }}
          />
        }
      </>
    )
  }
}
