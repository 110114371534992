import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import LazyLoadingList from '../../components/common/LazyLoadingList';
import AuthManager from '../../../utils/AuthManager'
import General from '../../../utils/General'
import Event from "../../../utils/Event";
import ListLinkie from "../modals/ListLinkie";
import Confirm from "../modals/Confirm";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import FetchHelper from "../../../utils/FetchHelper";
import Linkie from "./Linkie";

export default class Linkies extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      linkies: [],
      currentUser: AuthManager.currentUser,
      type: props.type,
      selectedList: props.selectedList
    }
    this.list = React.createRef()
  }

  componentDidMount() {
    this._setUpEvents()
    this._getLinkies()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.props.selectedList !== nextProps.selectedList){
      this.setState(nextProps, () => this._getLinkies())
    }
  }

  _setUpEvents(){
    Event.on('REFRESH_LINKIES', () => {
      this._getLinkies()
    })
  }

  _getLinkies(){
    let {
      type,
      selectedList,
      currentUser
    } = this.state

    let params = ""
    if(type === "read_later"){
      params = "list_type=read_later"
    }else if(type === "system_popular"){
      params = "list_type=system_popular"
    }

    if(type === "customer"){
      params = `list_id=${selectedList.parent ? selectedList.parent.id : selectedList.id}`
    }

    let endPoint = `${window.Api.Linkies}?${params}&paginated=false`

    Backend.getLinkies(endPoint).then(linkies => this.setState({linkies}))
  }

  refresh(){
    let current = this.list.current
    if(current){
      this._getLinkies()
    }
  }

  _handleSearch = General.debounce(() => {
    let current = this.list.current
    this.refresh()
  }, 500)

  _onDragEnd(result) {
    let {
      selectedList
    } = this.state

    if (!result.destination) {
      return;
    }

    const linkies = reorder(
      this.state.linkies,
      result.source.index,
      result.destination.index
    );

    let data = {linkies: linkies}

    this.setState({linkies})

    Backend.updateLinkies(selectedList.id, data).then(linkies => {
      General.showNotification("Collection Updated")
    }).catch(e => General.showNotification(e.message, false))
  }

  _renderLinkieHandle(linkie, index){
    return (
      <Draggable key={linkie.id} draggableId={linkie.id.toString()} index={index}>
        {(provided, snapshot) => (
          <div
            className="row linkie"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {this._renderLinkie(linkie, index)}
          </div>
        )}
      </Draggable>
    )
  }

  _renderLinkie(linkie, index){
    let {
      type,
      selectedList
    } = this.state

    return (
      <Linkie
        key={index}
        type={type}
        linkie={linkie}
        list={selectedList}
        refresh={() => this._getLinkies()}
      />
    )
  }

  render(){
    let {
      type,
      linkies,
      selectedLinkie,
      selectedList,
      showConfirmModal,
      showListLinkieModal
    } = this.state

    let addBookMarkPayload = type === "read_later" ? {list: {readLater: true}, linkie: null} : {list:selectedList, linkie: null}

    return (
      <>
        <div className="linkies">
          {
            (linkies.length < 1 && !selectedList.parent) &&
            <div className={"text-center"}>
              <h1 style={{paddingTop:20}}>{selectedList?.name}</h1>
              <h3 className="pt-2 pb-4">Add your first website to this list.</h3>
              <button
                type="button"
                className="btn btn-primary w-30 mb-3"
                onClick={() => Event.emit('SHOW_LINKIE_MODAL', addBookMarkPayload)}
              >
                Add New Website
              </button>
            </div>
          }
          {
            (linkies.length < 1 && selectedList.parent) &&
            <div className={"text-center"}>
              <h1 style={{paddingTop:20}}>{selectedList?.parent.name}</h1>
              <h3 className="pt-2 pb-4">There are currently no public websites on this collection.</h3>
            </div>
          }
          {
            type === "popular" || type === "read_later" ?
              linkies.map((linkie, index) => (
                <div className="row linkie">
                  {this._renderLinkie(linkie, index)}
                </div>
              ))
            :
              <DragDropContext onDragEnd={result => this._onDragEnd(result)}>
                <Droppable droppableId={`droppable${type}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {linkies.map((linkie, index) => this._renderLinkieHandle(linkie, index))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
          }
        </div>
      </>
    )
  }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#fbfbfb" : 'transparent',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid,
  background: isDragging ? "rgba(93, 120, 255, 0.4)" : "transparent",
  outline: 'none',
  ...draggableStyle
});
