import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ListManager } from "react-beautiful-dnd-grid";

import AuthManager from '../../../utils/AuthManager'
import General from '../../../utils/General'
import Event from "../../../utils/Event";
import Backend from "../../../utils/Backend";
import List from "./List";

export default class Lists extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      lists: [],
      currentUser: AuthManager.currentUser,
      type: props.type,
      selectedList: props.selectedList
    }
    this.list = React.createRef()
  }

  componentDidMount() {
    this._setUpEvents()
    this._getLists()
  }

  _setUpEvents(){
    Event.on('REFRESH_LIST', () => {
      this._getLists()
    })
  }

  _getLists(){
    Backend.getLists().then(lists => {
      this.setState({lists})
    }).catch(e => {
      General.showNotification(e.message, false)
    })
  }

  sort = () => {
    this.setState({
      ...this.state,
      lists: sort(this.state.lists)
    }, () => {
      let data = {lists: this.state.lists}

      Backend.updateLists(data).then(() => {
        General.showNotification("Collections Updated")
      }).catch(e => General.showNotification(e.message, false))
    })
  }

  reorder = (sourceIndex, destinationIndex) => {
    if (destinationIndex === sourceIndex) {
      return;
    }
    const list = this.state.lists;
    if (destinationIndex === 0) {
      list[sourceIndex].order = list[0].order - 1;
      this.sort();
      return;
    }
    if (destinationIndex === list.length - 1) {
      list[sourceIndex].order = list[list.length - 1].order + 1;
      this.sort();
      return;
    }
    if (destinationIndex < sourceIndex) {
      list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex - 1].order) / 2;
      this.sort();
      return;
    }
    list[sourceIndex].order = (list[destinationIndex].order + list[destinationIndex + 1].order) / 2;
    this.sort();
  }

  render(){
    let {
      lists,
    } = this.state

    return (
      <>
        <div className="linkies text-start">
          <ListManager
            items={lists}
            direction={"horizontal"}
            maxItems={4}
            render={item => (
              <List
                list={item}
                refresh={() => this._getLists()}
              />
            )}
            onDragEnd={this.reorder}
          />
        </div>
      </>
    )
  }
}

function sort(list) {
  return list.slice().sort((first, second) => first.order - second.order);
}
