import React from "react"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Linkies from "./components/linkie/Linkies";
import AuthManager from "../utils/AuthManager";
import Event from "../utils/Event";

export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activeKey: "my_linkies",
      currentUser: AuthManager.currentUser,
    }
    this._setUpEvents()
  }

  _setUpEvents(){
    Event.on('LIST_CHANGE', (list) => {
      this.setState({selectedList: list, activeKey: "my_linkies"})
    })
  }

  render() {
    let {
      activeKey,
      selectedList
    } = this.state

    if (!selectedList) return null

    return (
      <>
        <div className="g-5 gx-xxl-8">
          <div>
            <Linkies type={'customer'} selectedList={selectedList}/>
          </div>
        </div>
      </>
    )
  }

}
