import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'

export default class Backend {

  static addList(list){
    let data = JSON.parse(JSON.stringify(list))
    return FetchHelper.post(window.Api.Lists, data)
  }

  static updateList(list){
    let data = JSON.parse(JSON.stringify(list))
    return FetchHelper.patch(`${window.Api.Lists}/${list.id}`, data)
  }

  static addLinkie(linkie){
    let data = JSON.parse(JSON.stringify(linkie))
    return FetchHelper.post(window.Api.Linkies, data)
  }

  static updateLinkie(linkie){
    let data = JSON.parse(JSON.stringify(linkie))
    return FetchHelper.patch(`${window.Api.Linkies}/${linkie.id}`, data)
  }

  static deleteLinkie(linkie, list){
    return FetchHelper.delete(`${window.Api.Linkies}/${linkie.id}${list ? '?list_id='+list?.id : '?list_type=read_later'}`)
  }

  static deleteList(list){
    return FetchHelper.delete(`${window.Api.Lists}/${list.id}`)
  }

  static getLists(){
    return FetchHelper.get(`${window.Api.Lists}?paginated=false`)
  }

  static getList(list_id){
    return FetchHelper.get(`${window.Api.Lists}/${list_id}`)
  }

  static getLinkie(linkieID){
    return FetchHelper.get(`${window.Api.Linkies}/${linkieID}`)
  }

  static getLinkies(endpoint){
    return FetchHelper.get(endpoint)
  }

  static updateLinkies(list_id, data){
    data = JSON.parse(JSON.stringify(data))
    data.linkie_ids = data.linkies.map(link => link.id)
    return FetchHelper.patch(`${window.Api.Lists}/${list_id}`, data)
  }

  static saveLastList(data){
    data = JSON.parse(JSON.stringify(data))
    return FetchHelper.patch(`${window.Api.Customers}/${AuthManager.currentUser.user.id}`, data)
  }

  static updateLists(data){
    data = JSON.parse(JSON.stringify(data))
    data.list_ids = data.lists.map(list => list.id)
    return FetchHelper.put(`${window.Api.Lists}`, data)
  }

}