import React from "react"

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Linkies from "./components/linkie/Linkies";
import AuthManager from "../utils/AuthManager";
import Event from "../utils/Event";

export default class ReadLater extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="g-5 gx-xxl-8">
          <div>
            <Linkies type={'read_later'}/>
          </div>
        </div>
      </>
    )
  }

}